body img.mobileOnly,
body img.desktopOnly {
  display: none;
}

body img.mobileOnly {
  @media (width <= 767px) {
    display: block;
  }
}

body img.desktopOnly {
  @media (width >= 768px) {
    display: block;
  }
}

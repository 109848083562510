@import './legacy-mixins.pcss';

@define-mixin resetButton {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
}

@define-mixin stretch {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  /* stylelint-disable-next-line */
  left: 0;
}

@define-mixin stretchToEdges {
  position: relative;
  left: 50%;
  width: 100vw;
  transform: translateX(-50%);
}

@define-mixin stretchedBackground {
  position: absolute;
  top: 0;
  right: -3000px;
  bottom: 0;
  /* stylelint-disable-next-line */
  left: -3000px;
  z-index: -1;
}

/* $correction value should be with px, cuz it brake calc without */
/* stylelint-disable-next-line */
@define-mixin wrapperTablet $cols: 12, $gutters: 0, $correction: 0px, $width: 1004px, $padding: 44px,
  $gutter: 44px, $col: 36px {
  margin-right: auto;
  margin-left: auto;
  width: calc(
    $width - ($col * (12 - $cols)) - ($gutter * (11 - ($cols - 1))) - ($padding * 2) - $correction +
      ($gutters * $gutter)
  );
  max-width: 100%;
}

@define-mixin hiddenContent {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
}

@define-mixin youthBgDesktop {
  background: no-repeat 50% / cover #1a1a19
    url('https://static.beeline.ru/upload/images/digital-tariffs/mob_com_z_fare_card_desktop_9.jpg');
}

@define-mixin youthBgMobile {
  background: no-repeat center / cover #1a1a19
    url('https://static.beeline.ru/upload/images/digital-tariffs/tarif_z_bckg_mb.jpg');
}

@define-mixin youthBgCatalog {
  background: no-repeat center / cover #1a1a19
    url('https://static.beeline.ru/upload/images/digital-tariffs/tariff_Z_background_mobile.png');
}

@define-mixin youthCardFontFamily {
  font-family: 'Helvetica Neue', BeelineSans, sans-serif;
}

@define-mixin yandexPlusGradientCatalog {
  background: linear-gradient(
    17.01deg,
    #ff5c00 7.46%,
    #ea507c 26.17%,
    #b941ef 45.82%,
    #5b61ff 67.88%,
    #21bee7 92.34%
  );
}

@define-mixin yandexPlusGradient {
  background: linear-gradient(
    76.32deg,
    #ff5c00 3.51%,
    #ea507c 20.3%,
    #b941ef 38.1%,
    #5b61ff 63.54%,
    #21bee7 92.54%
  );
}

@define-mixin hideScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

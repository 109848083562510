@import 'styles/constants.pcss';

.tiles {
  position: relative;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  font-family: var(--fontPrimary);

  @media (--mobile) {
    padding: 8px 0;
  }

  &.rowButton {
    margin-top: -1px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  &.rowButton,
  &.borderButton {
    cursor: pointer;
  }

  &.actions,
  &.borderButton {
    border-top: 1px solid #fea05f;
    border-bottom: 1px solid #fea05f;
  }

  &.reversed {
    flex-direction: column-reverse;

    @media (--tablet) {
      flex-direction: row-reverse;
    }
  }
}

.leftBox,
.rightBox {
  width: 100%;
}

.leftBox {
  margin-top: 10px;

  & .bottomIcon {
    & .icon {
      margin: 10px 0 0;
      width: auto;
      height: 30px;
    }
  }
}

.reversed {
  .leftBox {
    margin-top: 0;
  }

  .rightBox {
    margin-top: 10px;
  }
}

.text {
  line-height: 1.4;
}

.about {
  display: flex;
  align-items: center;
  font-size: 18px;

  &.beeLogo,
  &.outerIconParam,
  &.unlimIcon {
    padding-right: 16px;
    display: block;

    & .text {
      padding-right: 25px;
    }
  }

  & .beeLogoIcon,
  & .outerIcon,
  & .unlimIcon {
    position: relative;
    top: 3px;
    z-index: 1;
    padding: 0;
    display: inline-block;
    width: 16px;
    height: 20px;

    @media (--tablet) {
      margin-left: -22px;
    }

    & > img {
      vertical-align: inherit;
      width: 100%;
      max-width: inherit;
    }
  }

  & .outerIcon {
    position: static;
    width: 20px;

    @media (--mobile) {
      margin-left: 2px;
    }

    @media (--tablet) {
      & > img {
        position: absolute;
        top: -3px;
        left: 2px;
        width: 25px;
        height: 25px;
      }
    }
  }

  & .valueIcon {
    margin-right: 5px;
    width: 30px;
    height: 20px;
  }
}

.description {
  padding-right: 25px;
  font-family: Arial, sans-serif;
  font-size: 13px;
  color: #767676;
}

.button,
.subDescription {
  font-family: Arial, sans-serif;
  font-size: 13px;
  color: #fea05f;
}

.subDescription {
  .icon {
    margin-right: 0;
    margin-left: 5px;
    display: inline-block;
    width: 10px;
    height: 10px;
  }
}

.row {
  position: absolute;
  top: 50%;
  right: 5px;
  flex-shrink: 0;
  transform: translateY(-50%);

  &::before {
    position: relative;
    margin-right: 5px;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-top: 1px solid #767676;
    border-right: 1px solid #767676;
    transform: rotate(45deg);
    content: '';
  }
}

.outerButton {
  position: absolute !important;
  top: -1px;
  right: 0;
  bottom: -1px;
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 100%;
  min-height: 100%;
  font-size: 0;
  cursor: pointer;

  &.clickable:hover {
    border-top: 1px solid #fea05f;
    border-bottom: 1px solid #fea05f;

    & ~ div {
      .text,
      .descriptionText {
        color: #fea05f;
      }
    }

    & ~ .row::before {
      border-top: 1px solid #fea05f;
      border-right: 1px solid #fea05f;
    }
  }
}

.actions,
.borderButton {
  .icon {
    margin: 0 10px;
    display: flex;
    flex-shrink: 0;
    width: 35px;
    height: 35px;

    img {
      width: inherit;
    }
  }

  .about {
    padding-right: 35px;
    font-size: 16px;
  }
}

.actions {
  & .about.fullWidth {
    padding-right: 0;

    & .infoBox {
      padding-right: 0;

      & .text {
        padding-right: 0;
      }
    }
  }

  .infoBox {
    padding-right: 25px;
    line-height: 19px;

    & .text {
      padding-right: 30px;
      font-family: var(--baseFontFamily);
      font-size: 13px;
    }

    & .textTooltip {
      position: relative;
      margin-left: -25px;
    }
  }

  &.layoutYoung {
    .infoBox {
      .text {
        font-family: var(--fontPrimary);
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.icon {
  margin-left: 5px;
  display: inline-block;
  width: 20px;
  height: 15px;

  img {
    width: 100%;
    height: 100%;
  }
}

.status {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  border-radius: 6px;

  &.error {
    background-color: #dc0000;
  }

  &.warning {
    color: #000;
    background-color: #fff7cb;
  }

  &.success {
    background-color: #80b980;

    span {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: -15px;
        content: '✓';
      }
    }
  }
}

.descriptionText {
  padding-right: 30px;
}

.descriptionTooltip {
  position: relative;
  z-index: 1;
  margin-left: -25px;
  padding: 0;
  vertical-align: unset;
}

.rowButtonParamsGroup {
  position: relative;
  padding: 20px 0;

  .groupItem {
    margin-bottom: 20px;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .row {
    top: 30px;
  }
}

@media (--tablet) {
  .tiles {
    flex-direction: row;
  }

  .leftBox,
  .rightBox {
    margin-top: 0;
    margin-right: 20px;
  }

  .reversed .rightBox {
    margin-top: 0;
  }

  .leftBox {
    width: 60%;

    &.discountLeftBox {
      width: 45%;
    }
  }

  .rightBox {
    width: 40%;

    & .about {
      position: relative;

      & .outerIcon {
        position: absolute;
        top: -3px;
        left: -40px;
        width: 40px;
        height: 40px;

        & > img {
          top: 0;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .borderButton {
    .about {
      font-size: 18px;
      line-height: normal;
    }
  }
}

.stats {
  & .infoBox {
    & .text {
      font-size: 17px;

      @media (--mobile) {
        font-size: 13px;
      }
    }
  }
}

.stats.actions {
  border-top: 1px solid #fac12b;
  border-bottom: 1px solid #fac12b;
}

.image {
  margin-top: 8px;
  width: 100%;

  img {
    max-width: 100%;
  }
}

.additionalSimImg {
  margin: 12px 0;
  width: 100%;

  @media (--tablet) {
    display: none;
  }
}

.additionalSimImgDesktop {
  display: none;
  cursor: default;

  @media (--tablet) {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: -175px;
    display: block;
    width: 145px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

@import 'styles/constants.pcss';
@import '@beef/ui-kit/styles/variables.pcss';
@import './mixins.pcss';
@import './legacy-constants.pcss';
@import './colors.pcss';

/* stylelint-disable */
html {
  font: 400 13px/1.4 Arial;
  color: #282828;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html body {
  margin: 0;
  min-width: 300px;
  background: #fff;
}

button,
input {
  font-family: inherit;
}

input[type='number'] {
  appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
}

iframe[src^='//creativecdn.com']
{
  @mixin hiddenContent;
}

:global * {
  outline: none;
}

:global {
  ul,
  li {
    margin: 0;
    padding: 0;
  }
}

:global .react-container {
  & * {
    box-sizing: border-box;
  }

  & iframe {
    max-width: 100%;
    border: none;
  }

  & p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:empty {
      display: none;

      &:first-child + p {
        margin-top: 0;
      }

      &:last-child + p {
        margin-bottom: 0;
      }
    }
  }

  & img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
  }

  & :global .descriptionHeader {
    font-size: 21px;

    &::before {
      display: block;
      height: 10px;
      content: '';
    }
  }

  & input::-ms-clear,
  & input::-ms-reveal {
    display: none;
  }
}

:global .old-value {
  position: relative;

  &::after {
    position: absolute;
    top: 10px;
    left: -2px;
    width: calc(100% + 5px);
    min-width: 45px;
    border-bottom: 1px solid #ff4e4e;
    transform: rotate(-20deg);
    content: '';
  }
}

:global .sr-only {
  position: absolute;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  border-width: 0;
}

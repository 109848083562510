@import 'styles/constants.pcss';

.component {
  font-family: var(--fontPrimary);
  font-size: 15px;
  line-height: 19px;
  text-align: left;

  @media (--tablet) {
    font-size: 17px;
    line-height: 25px;
    text-align: center;
  }
}

.image {
  margin-bottom: 20px;

  @media (--tablet) {
    margin-bottom: 40px;
  }
}

.noteText {
  margin: 16px 0 32px;
  font-size: 16px;
  color: #7a7a7a;
}

.emoji {
  margin-bottom: 15px;
  width: 60px;
}

.heading {
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 36px;
  color: #000;

  & img {
    width: 35px;
  }

  @media (--tablet) {
    margin-bottom: 25px;
    font-size: 50px;
    line-height: 56px;
  }
}

.descriptionWrap {
  margin-bottom: 25px;

  & mark {
    padding: 0 5px;
    background: #ffe02b;
  }

  abbr {
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
    font-size: 30px;
    line-height: 1.3;
    text-align: left;

    @media (--tablet) {
      margin-bottom: 20px;
      font-size: 50px;
      line-height: normal;
      text-align: center;
    }
  }

  & .paragraph {
    span {
      position: relative;
      top: 1px;
      left: 2px;
      padding-right: 15px;
    }

    & .tooltip {
      margin-left: -20px;
    }
  }
}

.descriptionIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: 0 10px;
    width: 15px;
  }
}

.description {
  font-family: var(--baseFontFamily);
  text-align: left;

  @media (--tablet) {
    text-align: center;
  }
}

.subButton {
  margin-top: 20px;
  display: block;
  text-align: center;
}

.qrCode {
  margin: 20px 0;
}

.gift {
  margin: 30px 0;
  max-width: 440px;
  text-align: left;

  @media (--tablet) {
    margin-right: auto;
    margin-left: auto;
  }
}

@font-face {
  font-family: BeelineSans;
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src:
    local('BeelineSans'),
    local('BeelineSans-Bold'),
    url(/react/build/fonts/BeelineSans-Bold.woff2) format('woff2'),
    url(/react/build/fonts/BeelineSans-Bold.woff) format('woff');
}

@font-face {
  font-family: BeelineSans;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src:
    local('BeelineSans'),
    local('BeelineSans-Medium'),
    url(/react/build/fonts/BeelineSans-Medium.woff2) format('woff2'),
    url(/react/build/fonts/BeelineSans-Medium.woff) format('woff');
}

@font-face {
  font-family: BeelineSans;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src:
    local('BeelineSans'),
    local('BeelineSans-Regular'),
    url(/react/build/fonts/BeelineSans-Regular.woff2) format('woff2'),
    url(/react/build/fonts/BeelineSans-Regular.woff) format('woff');
}
